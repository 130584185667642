

































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      form: {
        email: '',
        password: '',
        captchaToken: '',
      },
      error: null,
    };
  },
  async mounted() {
    await this.$store.dispatch('presenter/checkForLastSession');
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMessage',
    }),
  },
  methods: {
    async submitForm() {
      this.$store.dispatch('auth/signInWithEmail', { ...this.form });
    },
  },
});
